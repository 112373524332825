<template>
  <v-container>
    <v-overlay z-index="0" value="true" opacity="1" :color="clrOverlay">
      <v-row dense>
        <v-col align="center" justify="center">
          <LoadingComponent v-if="loading" />
        </v-col>
      </v-row>
      <v-layout align-center justify-center>
        <v-form>
          <v-card light class="elevation-12 mx-auto my-12" width="50vh">
            <v-toolbar>
              <v-row dense>
                <v-col cols="4">
                  <v-img :src="nbLogo"></v-img>
                </v-col>
                <v-col></v-col>
              </v-row>
            </v-toolbar>

            <v-carousel
              cycle
              height="250"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item v-for="(item, i) in carouselImages" :key="i">
                <img :src="item.src" :alt="item.src" width="100%" />
              </v-carousel-item>
            </v-carousel>
            <v-card-title>Login</v-card-title>
            <v-card-text>
              <v-alert color="error" v-if="error" dense>
                <v-icon @click="closeErrorMsg">close</v-icon>User does not
                exist.
              </v-alert>
              <v-text-field
                v-model="username"
                prepend-icon="person"
                outlined
                dense
                name="login"
                label="Login"
                type="text"
                @keyup.enter="login"
              ></v-text-field>

              <v-text-field
                v-model="password"
                prepend-icon="lock"
                outlined
                dense
                name="password"
                label="Password"
                type="password"
                @keyup.enter="login"
              ></v-text-field>
            </v-card-text>
            <v-divider light></v-divider>
            <v-card-actions>
              <v-btn :color="clrBtn" small dark raised @click="signUp"
                >Sign up</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn :color="clrBtn" small dark raised @click="login">
                Login
                <v-icon>keyboard_arrow_right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-layout>
    </v-overlay>
  </v-container>
</template>

<script>
import LoadingComponent from "../core/loading";
import * as commonjson from "../../shared/common-json";
import * as constants from "../../shared/constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "login",
  components: { LoadingComponent },
  data: () => ({
    username: "",
    password: "",
    carouselImages: commonjson.CarouselImages,
    clrBtn: constants.CLR_BTN,
    clrOverlay: constants.CLR_OVERLAY,
    nbLogo: constants.NB_LOGO,
  }),
  computed: {
    ...mapGetters(["isAuthenticated", "getAuthStatus"]),
    loading: function() {
      return this.getAuthStatus === "loading" && !this.isAuthenticated;
    },
    error: function() {
      return this.getAuthStatus === "error";
    },
  },
  methods: {
    ...mapActions(["authRequest", "authReset", "getApiSettingsAction"]),

    async login() {
      const dataObj = {
        username: this.username,
        password: this.password,
      };
      await this.authRequest(dataObj);
      await this.getApiSettingsAction();
      this.$router.push({ name: "home" });
    },

    async signUp() {
      await this.authReset();
      this.$router.push({ name: "signup" });
    },

    async closeErrorMsg() {
      await this.authReset();
    },
  },
};
</script>
