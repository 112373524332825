export const CarouselImages = [
  {
    src:
      "https://res.cloudinary.com/lexcom/image/upload/v1596837995/site/carousel-1.jpg",
  },
  {
    src:
      "https://res.cloudinary.com/lexcom/image/upload/v1596837995/site/carousel-2.jpg",
  },
  {
    src:
      "https://res.cloudinary.com/lexcom/image/upload/v1596837995/site/carousel-3.jpg",
  },
  {
    src:
      "https://res.cloudinary.com/lexcom/image/upload/v1596837995/site/carousel-4.jpg",
  },
];

export const ProvinceList = [
  { name: "Alberta", abbreviation: "AB" },
  { name: "British Columbia", abbreviation: "BC" },
  { name: "Manitoba", abbreviation: "MB" },
  { name: "New Brunswick", abbreviation: "NB" },
  { name: "Newfoundland and Labrador", abbreviation: "NL" },
  { name: "Northwest Territories", abbreviation: "NT" },
  { name: "Nova Scotia", abbreviation: "NS" },
  { name: "Nunavut", abbreviation: "NU" },
  { name: "Ontario", abbreviation: "ON" },
  { name: "Prince Edward Island", abbreviation: "PE" },
  { name: "Quebec", abbreviation: "QC" },
  { name: "Saskatchewan", abbreviation: "SK" },
  { name: "Yukon Territory", abbreviation: "YT" },
];

export const YODeliveryServiceList = [
  "Hand delivered",
  "Posted",
  "Regular mail",
];