<template>
  <v-container>
    <v-overlay z-index="0" value="true" opacity="1" :color="clrOverlay">
      <v-layout align-center justify-center>
        <v-form>
          <v-card light class="elevation-12 mx-auto my-12" width="50vh">
            <v-toolbar>
              <v-row dense>
                <v-col cols="4">
                  <v-img
                    src="https://res.cloudinary.com/lexcom/image/upload/v1596850643/site/nb.jpg"
                  ></v-img>
                </v-col>
                <v-col></v-col>
              </v-row>
            </v-toolbar>
            <v-carousel
              cycle
              height="165"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item v-for="(item, i) in carouselImages" :key="i">
                <img :src="item.src" :alt="item.src" width="100%" />
              </v-carousel-item>
            </v-carousel>
            <v-card-title>Sign Up</v-card-title>
            <v-card-text>
              <v-alert :value="error" color="error" icon="warning">
                {{ this.errorMessage }}
              </v-alert>
              <v-text-field
                prepend-icon="person"
                outlined
                dense
                v-model="fullname"
                label="Name"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                prepend-icon="person"
                outlined
                dense
                v-model="username"
                label="Login"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                prepend-icon="email"
                outlined
                dense
                v-model="email"
                label="Email"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
              <v-text-field
                prepend-icon="lock"
                outlined
                dense
                label="Password"
                :rules="[rules.required]"
                type="password"
                v-model="password"
              ></v-text-field>
              <v-text-field
                prepend-icon="lock"
                outlined
                dense
                label="Confirm Password"
                :rules="[rules.required]"
                type="password"
                v-model="confirmPassword"
              ></v-text-field>
            </v-card-text>
            <v-divider light></v-divider>
            <v-card-actions>
              <v-btn to="/login" :color="clrBtn" small dark raised>Login</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :color="clrBtn"
                small
                dark
                raised
                @click.prevent="register()"
              >
                Register
                <v-icon>keyboard_arrow_up</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-layout>
    </v-overlay>
  </v-container>
</template>

<script>
import * as commonjson from "../../shared/common-json";
import * as constants from "../../shared/constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "signup",
  data: () => ({
    fullname: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    error: false,
    errorMessage: "",
    carouselImages: commonjson.CarouselImages,
    clrBtn: constants.CLR_BTN,
    clrOverlay: constants.CLR_OVERLAY,
    rules: {
      required: (value) => !!value || "Required",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  computed: {
    ...mapGetters(["getUserRegisterationStatus"]),
  },
  methods: {
    ...mapActions(["registerUser"]),

    async register() {
      if (this.password != this.confirmPassword) {
        this.error = true;
        this.errorMessage = "Password and Confirm Password are not same.";
        return;
      }

      const dataObj = {
        fullname: this.fullname,
        email: this.email,
        username: this.username,
        password: this.password,
        confirmPassword: this.confirmPassword,
      };
      await this.registerUser(dataObj);

      if (!this.getUserRegisterationStatus) {
        this.error = true;
        this.errorMessage = "User already exists.";
        return;
      }

      this.$router.push({ name: "login" });
    },
  },
};
</script>
